<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{$t("benefit.title")}}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('benefit.search')"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{$t("benefit.create")}}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listBenefit.length > 0">
          <thead>
          <tr>
            <th class="text-center"></th>
            <th class="text-left">
              {{$t("benefit.benefitName")}}
            </th>
            <th class="text-left">
             {{$t("benefit.created")}}
            </th>
            <th class="text-left">
              {{$t("benefit.updated_At")}}
            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in listBenefit" :key="index">
            <!-- <td>{{ (pagination.current_page * 10) - 10 + index + 1 }}</td> -->
            <td>{{ index + 1 }}</td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.createdAt }}
            </td>
            <td>
              {{ item.updatedAt }}
            </td>
            <td>

            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <default-table-no-result v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchBenefits"
        >
        </Pagination>
        <ModalEdit fullscreen title="Edit Benefit">
          <template v-slot="{ close }">
            <EditBenefit :benefit="benefit" @close="close" @success="fetchBenefits()"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <DeleteBenefit :benefit_id="benefit_id" @close="close" @success="fetchBenefits()"/>
          </template>
        </ModalDelete>
        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import EditBenefit from "@/components/Benefit/EditBenefit";
import DeleteBenefit from "@/components/Benefit/DeleteBenefit";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    EditBenefit,
    DeleteBenefit
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,

      isLoading: true,
      modalDeletePosition: false,
      benefit: {},
      benefit_id: "",
      listBenefit: [],
    }
  },
  methods: {
    searchFilterItem() {
      this.fetchBenefits();
    },
    onCreate() {
      console.log('vvv')
      this.$router.push({name: 'benefit.create'}).catch(() => {
      });
    },

    filterBenefit(benefit_id) {
      return (
          this.listBenefit.filter((item) => {
            return item.id == benefit_id;
          })[0] || {}
      );
    },

    onEdit(benefit_id) {
      this.benefit = {
        ...this.filterBenefit(benefit_id)
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(benefit_id) {
      this.benefit_id = benefit_id;
      this.$store.commit("modalDelete_State", true);
    },


    fetchBenefits() {
      this.isLoading = true;
      this.$axios.get(`company/list/benefits`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        console.log(res)
        if (res) {
          this.isLoading = false;
          this.listBenefit = res.data.benefits;
          // this.pagination = res.data.data.pagination;
          // if (!this.listBranch.length > 0) {
          //   this.pagination.current_page = this.pagination.current_page - 1;
          // }
        }
      }).catch((error) => {
        console.log(error)
        this.isLoading = false;
      })
    },
  },
  created() {
    this.fetchBenefits();
  }
}
</script>

<style scoped lang="scss">
</style>
