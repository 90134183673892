<template>
  <div class="benefit-form">
    <v-form class="form" ref="form" @submit.prevent="validateForm">
      <v-row>
        <v-col cols="8">
          <v-text-field
              outlined
              dense
              class="label-input"
              :label="$t('benefit.benefitName')"
              :rules="nameRules"
              v-model="benefit.name"
              :hint="`${server_errors.name}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>

        <v-col cols="8">
          <v-btn class="btn-save-change" type="submit">
            {{ $t("benefit.edit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    benefit: {},
  },
  data() {
    return {
      nameRules: [
        v => !!v || 'ກະລຸນາປ້ອນ...'
      ],
      btnLoading: false,
      server_errors: {
        name: "",
      }
    }
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        this.UpdateItem();
        this.btnLoading = true
      }
    },

    resetForm() {
      this.$refs.form.reset();
    },

    UpdateItem() {
      this.$axios.put(`company/edit/benefit`, this.benefit, {
        params: {
          id: this.benefit.id,
        }
      }).then((res) => {
        if (res) {
          setTimeout(() => {
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
            this.$emit("success");
            this.$emit("close");
          }, 300);
        }
        this.btnLoading = false;
        this.resetForm();
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
}
</script>

<style scoped lang="scss">
.benefit-form {
  width: 100%;
  height: 600px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    width: 600px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
</style>
